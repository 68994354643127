// Generated by Framer (926e2eb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {ROR2R7LCd: {hover: true}};

const cycleOrder = ["ROR2R7LCd"];

const variantClassNames = {ROR2R7LCd: "framer-v-18t3e5z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "ROR2R7LCd", tap: R_YZLn36R, image: H4gQuF6H4, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ROR2R7LCd", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapkb3q86 = activeVariantCallback(async (...args) => {
if (R_YZLn36R) {
const res = await R_YZLn36R(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-frlYF", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-18t3e5z", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ROR2R7LCd"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"ROR2R7LCd-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "400px", ...toResponsiveImage(H4gQuF6H4)}} className={"framer-dxfvig"} data-framer-name={"Image"} data-highlight layoutDependency={layoutDependency} layoutId={"GXG0Amtu9"} onTap={onTapkb3q86} style={{opacity: 1}} transition={transition} variants={{"ROR2R7LCd-hover": {opacity: 0.47}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-frlYF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-frlYF * { box-sizing: border-box; }", ".framer-frlYF .framer-lbfzyf { display: block; }", ".framer-frlYF .framer-18t3e5z { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-frlYF .framer-dxfvig { aspect-ratio: 0.8 / 1; cursor: pointer; flex: none; height: 500px; position: relative; width: var(--framer-aspect-ratio-supported, 400px); }", ".framer-frlYF .framer-v-18t3e5z .framer-18t3e5z { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-frlYF .framer-18t3e5z { gap: 0px; } .framer-frlYF .framer-18t3e5z > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-frlYF .framer-18t3e5z > :first-child { margin-left: 0px; } .framer-frlYF .framer-18t3e5z > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 500
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"BlIacXezp":{"layout":["auto","auto"]}}}
 * @framerVariables {"R_YZLn36R":"tap","H4gQuF6H4":"image"}
 */
const FramergJ4rrXuC3: React.ComponentType<Props> = withCSS(Component, css, "framer-frlYF") as typeof Component;
export default FramergJ4rrXuC3;

FramergJ4rrXuC3.displayName = "Image Wrapper";

FramergJ4rrXuC3.defaultProps = {height: 500, width: 400};

addPropertyControls(FramergJ4rrXuC3, {R_YZLn36R: {title: "Tap", type: ControlType.EventHandler}, H4gQuF6H4: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramergJ4rrXuC3, [])